// HomeLiveStreamFeed.jsx
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { LIVESTREAM_FEED } from '../RemoteConfigKeys.js';
import { InfiniteScroller, ScrollItem } from './InfiniteScroller.jsx';
import WithIntersectionObserver from './WithIntersectionObserver.jsx';
import media from '../style/media.js';
import { color, textColor, loadingIconOpacity } from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import { livestreamAdType as livestreamAdTypeConstant } from '../resource/liveStreamConstants.js';
import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { QueryType } from '../resource/feedConstants.js';
import HomeLiveStreamAd from '../container/HomeLiveStreamAd.js';
import SortButton from '../container/SortButton.js';
import FilterButton from '../container/FilterButton.js';
import ToggleSwitch from '../component/ToggleSwitch.jsx';
import UniversalContainer from '../component/UniversalContainer.jsx';
import withRouter from '../component/WithRouter.jsx';
import UserHashtagCard from '../container/UserHashtagCard.js';

import LoadingIconResource from '../../img/img-loading-grey-20.svg';
import EmptyLivestreamIconSrouce from '../../img/img_no_live.svg';

export class HomeLiveStreamFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    // fetch data for SSR
    const {
      staticContext,
      isRemoteConfigMerged,
      isFirstPageFetched,
      feedNameWithQuery,
      fetchFeeds,
    } = props;
    if (staticContext.actions) {
      if (isRemoteConfigMerged && !isFirstPageFetched) {
        staticContext.actions.push({
          function: fetchFeeds,
          argumentObject: {
            type: feedNameWithQuery,
            page: 1,
            httpProxyHeaders: staticContext.httpProxyHeaders,
          },
        });
      }
    }
  }

  intervalId = null;
  pendingFetch = null;
  state = {
    shouldAutoRenewFeed: false,
  };
  getLatestFeedRenewUnix = () => {
    const { feedRenewObject } = this.props;
    return Object.values(feedRenewObject)
      .filter(item => item.timestamp != null)
      .sort((a, b) => b.timestamp - a.timestamp)[0]?.timestamp;
  };
  fetchFeeds = ({ page = 1 } = {}) => {
    const {
      shouldRefresh,
      isRemoteConfigMerged,
      feedNameWithQuery,
      renewFeedId,
      latestFeedRenewUnix,
      fetchFeeds,
    } = this.props;
    if (feedNameWithQuery) {
      if (isRemoteConfigMerged) {
        fetchFeeds({
          id: renewFeedId,
          type: feedNameWithQuery,
          page,
          unixTimestamp: latestFeedRenewUnix,
          shouldSkipLocalCache: shouldRefresh,
        });
      } else {
        this.pendingFetch = () =>
          fetchFeeds({
            id: renewFeedId,
            type: feedNameWithQuery,
            page,
            unixTimestamp: latestFeedRenewUnix,
            shouldSkipLocalCache: shouldRefresh,
          });
      }
    }
  };
  subscribeFeedChannel = ({
    intersectedFeedName = '',
    prevIntersectedFeedName = '',
  } = {}) => {
    const { subscribeFeedChannel, unsubscribeFeedChannel } = this.props;
    const intersectedFeedNames = intersectedFeedName.split('&');
    const prevIntersectedFeedNames = prevIntersectedFeedName.split('&');
    const toUnsubscribeFeedNames = prevIntersectedFeedNames.filter(
      name => !intersectedFeedNames.includes(name)
    );
    const toSubscribeFeedNames = intersectedFeedNames.filter(
      name => !prevIntersectedFeedNames.includes(name)
    );
    toUnsubscribeFeedNames.forEach(feedName =>
      unsubscribeFeedChannel({ feedName })
    );
    toSubscribeFeedNames.forEach(feedName =>
      subscribeFeedChannel({ feedName })
    );
  };
  componentDidMount() {
    const { intersectedFeedName } = this.props;
    this.fetchFeeds({ page: 1 });
    this.subscribeFeedChannel({
      intersectedFeedName,
    });
  }
  componentDidUpdate(prevProps) {
    const {
      isAuthed,
      isRemoteConfigMerged,
      feedNameWithQuery,
      intersectedFeedName,
      isBrowserTabVisible,
      shouldUseCuratorMode,
      renewFeedId,
      shouldRefresh,
    } = this.props;
    if (intersectedFeedName !== prevProps.intersectedFeedName) {
      this.subscribeFeedChannel({
        intersectedFeedName,
        prevIntersectedFeedName: prevProps.intersectedFeedName,
      });
    }
    if (
      isAuthed !== prevProps.isAuthed ||
      isRemoteConfigMerged !== prevProps.isRemoteConfigMerged ||
      feedNameWithQuery !== prevProps.feedNameWithQuery
    ) {
      if (feedNameWithQuery !== prevProps.feedNameWithQuery) {
        this.fetchFeeds({ page: 1 });
      } else if (this.pendingFetch) {
        this.pendingFetch();
        this.pendingFetch = null;
      }
    }

    if (
      isBrowserTabVisible &&
      isBrowserTabVisible !== prevProps.isBrowserTabVisible
    ) {
      this.fetchFeeds({ page: 1 });
    }

    if (renewFeedId !== prevProps.renewFeedId && shouldUseCuratorMode) {
      const { shouldAutoRenewFeed } = this.state;
      if (shouldAutoRenewFeed) this.fetchFeeds({ page: 1 });
    }

    if (shouldRefresh && shouldRefresh !== prevProps.shouldRefresh) {
      this.fetchFeeds({ page: 1 });
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
    if (!window.__IS_HYDRATED__) {
      window.__IS_HYDRATED__ = 'HomeLiveStreamFeed';
    }
  }
  renderLoading = () => {
    return (
      <LoadingWrapper>
        <LoadingIcon src={LoadingIconResource} alt="loading icon" />
      </LoadingWrapper>
    );
  };

  renderUserItem = (id, index) => {
    const { swaggerOnlineFeed } = this.props;
    return (
      <UniversalContainer
        filename="UserFeedItem"
        categoryId={swaggerOnlineFeed}
        categoryIndex={index}
        id={id}
        key={id}
        itemIndexInCategory={index}
        pathname={'flix'}
        withHalo={true}
        marginTop={20}
        size={92}
        shouldTrackEvent
      />
    );
  };
  renderFilterEmptyResult = () => {
    const { category, t, setSelectedFeedQueryOption } = this.props;
    return (
      <QueryResultEmptyWrapper>
        <QueryEmptyIcon
          src={EmptyLivestreamIconSrouce}
          width="120"
          height="120"
        />
        <EmptyFilterWording>
          {t('filter_content_no_found', {
            ns: TranslationNamespace.FEED,
          })}
        </EmptyFilterWording>
        <EmptyFilterWording>
          {t('or', {
            ns: TranslationNamespace.GENERAL,
          })}
        </EmptyFilterWording>
        <ResetFilterButton
          data-element_id={ButtonId.LiveStream.ButtonLivestreamResetFilter}
          onClick={() => {
            return setSelectedFeedQueryOption({
              queryType: QueryType.FILTER,
              remoteConfigKeyName: LIVESTREAM_FEED,
              baseFeedName: category,
            });
          }}
        >
          {t('reset_filter', {
            ns: TranslationNamespace.FEED,
          })}
        </ResetFilterButton>
      </QueryResultEmptyWrapper>
    );
  };
  renderEmptyRecommendUsers = () => {
    const {
      fetchFeeds,
      onlineUserIds,
      swaggerOnlineFeed,
      onlineUserNextPage,
      isOnlineUserNextPageFetching,
    } = this.props;
    return (
      <RecommendWrapper>
        <RecommendUsers>
          <InfiniteScroller
            pageStart={1}
            hasMore={onlineUserNextPage !== null}
            loadMore={() => {
              onlineUserNextPage
                ? fetchFeeds({
                    type: swaggerOnlineFeed,
                    page: onlineUserNextPage,
                  })
                : null;
            }}
            isFetching={isOnlineUserNextPageFetching}
            threshold={100}
          >
            <RecommendUsersWrpper>
              <ScrollItem loader={this.renderLoading()}>
                {onlineUserIds.map(this.renderUserItem)}
              </ScrollItem>
            </RecommendUsersWrpper>
          </InfiniteScroller>
        </RecommendUsers>
      </RecommendWrapper>
    );
  };

  render() {
    const {
      t,
      category,
      feedNameWithQuery,
      titleI18nId,
      userIds,
      nextPage,
      selectedFilterOptions,
      isFirstPageFetching,
      isFirstPageFetched,
      isNextPageFetching,
      isOnlineUserFirstPageFetching,
      isSquare,
      shouldUseCuratorMode,
    } = this.props;
    const { shouldAutoRenewFeed } = this.state;
    const isLoading =
      (isFirstPageFetching && userIds?.length === 0) ||
      isOnlineUserFirstPageFetching;
    const isEmpty = !isLoading && userIds.length === 0;
    return (
      <StyledHomeLiveStreamFeed>
        <Header>
          <HeaderLeft>
            <Title>
              {t(titleI18nId, {
                ns: TranslationNamespace.FEED,
              })}
            </Title>
          </HeaderLeft>
          <HeaderRight>
            <ConditionsWrapper>
              <FilterButton
                remoteConfigKeyName={LIVESTREAM_FEED}
                baseFeedName={category}
                mixpanelButtonId={ButtonId.LiveStream.ButtonLivestreamFilter}
              />
              <SortButton
                remoteConfigKeyName={LIVESTREAM_FEED}
                baseFeedName={category}
                mixpanelButtonId={ButtonId.LiveStream.ButtonLivestreamSorting}
                defaultItemI18nKey={'livestream_default_sorting'}
              />
              {shouldUseCuratorMode && (
                <AutoUpdateButton>
                  <ToggleSwitch
                    active={shouldAutoRenewFeed}
                    onToggle={() => {
                      this.setState({
                        shouldAutoRenewFeed: !shouldAutoRenewFeed,
                      });
                    }}
                  />
                  <span>
                    {t('button_update', {
                      ns: TranslationNamespace.FEED,
                    })}
                  </span>
                </AutoUpdateButton>
              )}
            </ConditionsWrapper>
          </HeaderRight>
        </Header>

        {isLoading ? (
          this.renderLoading()
        ) : selectedFilterOptions.length > 0 && isEmpty ? (
          this.renderFilterEmptyResult()
        ) : isFirstPageFetched && !userIds.length ? (
          this.renderEmptyRecommendUsers()
        ) : !isFirstPageFetched && !userIds.length ? (
          this.renderLoading()
        ) : (
          <InfiniteScroller
            pageStart={1}
            hasMore={nextPage !== null}
            loadMore={() => {
              nextPage
                ? this.fetchFeeds({
                    page: nextPage,
                  })
                : null;
            }}
            isFetching={isNextPageFetching}
            threshold={100}
          >
            <LiveStreamCardsWrapper>
              <ScrollItem loader={this.renderLoading()}>
                {userIds.map((userId, index) => {
                  if (isSquare && !shouldUseCuratorMode) {
                    if (
                      [
                        livestreamAdTypeConstant.SWAGGER,
                        livestreamAdTypeConstant.ADVERTISER,
                      ].includes(userId)
                    ) {
                      return shouldUseCuratorMode ? null : ( // skip ad
                        <div key={userId}>
                          <HomeLiveStreamAd adType={userId} />
                        </div>
                      );
                    }
                    return (
                      <UserHashtagCard
                        key={userId}
                        userId={userId}
                        category={feedNameWithQuery}
                        index={index}
                      />
                    );
                  } else {
                    if (
                      [
                        livestreamAdTypeConstant.SWAGGER,
                        livestreamAdTypeConstant.ADVERTISER,
                      ].includes(userId)
                    ) {
                      return shouldUseCuratorMode ? null : ( // skip ad
                        <HomeLiveStreamAd key={userId} adType={userId} />
                      );
                    }
                    return (
                      <WithIntersectionObserver key={userId} shouldKeepObserve>
                        {({ isIntersecting }) => (
                          <LiveStreamCardWrapper>
                            {shouldUseCuratorMode ? (
                              <UniversalContainer
                                filename="LiveStreamCuratorCard"
                                userId={userId}
                                streamId={userId}
                                isIntersecting={isIntersecting}
                              />
                            ) : (
                              <UniversalContainer
                                filename="LiveStreamCard"
                                userId={userId}
                                streamId={userId}
                                isIntersecting={isIntersecting}
                                category={feedNameWithQuery}
                                index={index}
                              />
                            )}
                          </LiveStreamCardWrapper>
                        )}
                      </WithIntersectionObserver>
                    );
                  }
                })}
              </ScrollItem>
            </LiveStreamCardsWrapper>
          </InfiniteScroller>
        )}
      </StyledHomeLiveStreamFeed>
    );
  }
}

HomeLiveStreamFeed.propTypes = {
  staticContext: PropTypes.object,
  isAuthed: PropTypes.bool,
  isRemoteConfigMerged: PropTypes.bool,
  category: PropTypes.string,
  titleI18nId: PropTypes.string,
  feedNameWithQuery: PropTypes.string,
  intersectedFeedName: PropTypes.string,
  swaggerOnlineFeed: PropTypes.string,
  renewFeedId: PropTypes.string,
  latestFeedRenewUnix: PropTypes.number,
  userIds: PropTypes.array,
  selectedFilterOptions: PropTypes.array,
  nextPage: PropTypes.number,
  isNextPageFetching: PropTypes.bool,
  isFirstPageFetching: PropTypes.bool,
  isFirstPageFetched: PropTypes.bool,
  shouldRefresh: PropTypes.bool,
  onlineUserIds: PropTypes.array,
  onlineUserNextPage: PropTypes.number,
  isOnlineUserNextPageFetching: PropTypes.bool,
  isOnlineUserFirstPageFetching: PropTypes.bool,
  isSquare: PropTypes.bool,
  feedRenewObject: PropTypes.object,
  fetchFeeds: PropTypes.func,
  subscribeFeedChannel: PropTypes.func,
  unsubscribeFeedChannel: PropTypes.func,
  isBrowserTabVisible: PropTypes.bool,
  setSelectedFeedQueryOption: PropTypes.func,
  t: PropTypes.func.isRequired,
  shouldUseCuratorMode: PropTypes.bool,
};

HomeLiveStreamFeed.defaultProps = {
  staticContext: {},
  isAuthed: false,
  isRemoteConfigMerged: false,
  category: null,
  titleI18nId: '',
  feedNameWithQuery: null,
  intersectedFeedName: '',
  swaggerOnlineFeed: null,
  renewFeedId: null,
  latestFeedRenewUnix: null,
  userIds: [],
  selectedFilterOptions: [],
  nextPage: null,
  isNextPageFetching: false,
  isFirstPageFetching: false,
  isFirstPageFetched: false,
  shouldRefresh: false,
  onlineUserIds: [],
  onlineUserNextPage: null,
  isOnlineUserNextPageFetching: false,
  isOnlineUserFirstPageFetching: false,
  isSquare: false,
  feedRenewObject: {},
  fetchFeeds: () => null,
  subscribeFeedChannel: () => null,
  unsubscribeFeedChannel: () => null,
  setSelectedFeedQueryOption: () => null,
  isBrowserTabVisible: true,
  shouldUseCuratorMode: false,
};

const StyledHomeLiveStreamFeed = styled.div``;

const Header = styled.header`
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  flex: auto;
`;

const Title = styled.h2`
  margin: 0;
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  ${media.mobile`
    font-size: 18px;
  `}
`;

const HeaderRight = styled.div`
  margin-left: 8px;
  flex: none;
`;

const ConditionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    :first-child {
      margin-left: 0px;
    }
    margin-left: 8px;
  }
  :empty {
    padding: 0px;
    margin-bottom: 20px;
    ${media.mobile`
      margin-bottom: 12px;
    `};
  }
`;

const LiveStreamCardsWrapper = styled.div`
  padding: 8px 16px 16px;
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  ${media.tablet`
    /* padding: 12px; */
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  `}
`;

const LiveStreamCardWrapper = styled.div``;

const LoadingWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  opacity: ${loadingIconOpacity};
`;

const LoadingIcon = styled.img`
  width: 32px;
  height: auto;
`;

const RecommendWrapper = styled.div`
  position: relative;
  padding-top: 28px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 16px;
  background-color: ${color.grey['900']};
`;

const RecommendUsers = styled.div``;
const RecommendUsersWrpper = styled.div`
  display: grid;
  gap: 20px 14px;
  grid-template-columns: repeat(auto-fill, minmax(104px, 1fr));
`;

const QueryResultEmptyWrapper = styled.div`
  margin-top: 48px;
  text-align: center;
  font-size: 16px;
  color: ${textColor.grey};
  ${media.mobile`
    font-size: 14px;
  `};
`;

const QueryEmptyIcon = styled.img`
  margin-bottom: 12px;
  width: 120px;
  height: 120px;
  object-fit: contain;
  ${media.mobile`
    margin-bottom: 8px;
  `};
`;

const EmptyFilterWording = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  max-width: 260px;
  line-height: 1.6;
  white-space: pre-wrap;
  ${media.mobile`
    margin-bottom: 8px;
  `};
`;

const ResetFilterButton = styled.button`
  ${ResetButtonStyle};
  margin: 0px auto;
  border: 1px solid ${color.white};
  border-radius: 4px;
  padding: 0px 8px;
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${textColor.white};
  ${media.mobile`
    height: 28px;
    font-size: 12px;
  `};
`;

const AutoUpdateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  > span {
    margin-left: 4px;
  }
`;

export default withRouter(withTranslation()(HomeLiveStreamFeed));
